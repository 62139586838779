import React from "react";
import "./Fridge.css";

function Fridge(props) {
    return (
        <div className="fridge">
            <h1>Fridge</h1>
            <p>[CURRENTLY UNDER CONSTRUCTION]</p>
        </div>
    );
}

export default Fridge;
