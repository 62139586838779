import React, { useState } from "react";
import "./Cook.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Cook(props) {
    const activeRecipe = props.activeRecipe;

    if (activeRecipe == null) {
        return (
            <div className="cook-container">
                <h1>Welcome to cook mode</h1>
                <p>Please select a recipe from recipes, and click "cook"</p>
            </div>
        );
    } else {
        return (
            <div className="root-cook-container">
                <div className="side-container">
                    <button
                        className="button back-button"
                        onClick={props.handleRecipeButtonPressed}
                    >
                        <FontAwesomeIcon
                            className="fa-shadow"
                            icon={faArrowLeft}
                            size="1x"
                        />
                        <p className="back-button-text">BACK</p>
                    </button>
                </div>
                <div className="cook-container">
                    <div className="title-container">
                        <img
                            className="recipe-image small"
                            src={process.env.REACT_APP_API_URL + activeRecipe.img_uri}
                            alt={activeRecipe.name}
                        />
                        <h1>{activeRecipe.name}</h1>
                    </div>
                    <div className="steps-list">
                        {activeRecipe.steps.map((step, index) => (
                            <div className="steps-list-item">
                                <p className="step-header-cook">
                                    Step {index + 1}
                                </p>
                                <p className="step-contents-cook">
                                    {step.step}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Cook;
