import React from "react";
import "./ShoppingList.css";

function ShoppingList(props) {
    return (
        <div className="shoppinglist">
            <h1>Shopping list</h1>
            <p>[CURRENTLY UNDER CONSTRUCTION]</p>
        </div>
    );
}

export default ShoppingList;
