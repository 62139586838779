import React, { useState } from "react";
import "./Modal.css";
import RecipeGrid from "./Modal/RecipeGrid";

function Modal(props) {
    const { modalVisible, setModalVisible, modalType, userToken } = props;

    const [pickedRecipes, setPickedRecipes] = useState([
        null,
        null,
        null,
        null,
        null,
        null,
        null
    ]);

    const fetchOneRecipe = async (index) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/recipe/" + index,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json;"
                    }
                }
            );
            if (!response.ok) {
                console.log(response);
                throw new Error("Network response was not ok");
                return null;
            }
            const responseData = await response.json();
            console.log(responseData);
            return responseData;
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        } finally {
            //
        }
    };

    const createCancellablePromise = (promise) => {
        const cancellablePromise = new Promise(async (resolve, reject) => {
            try {
                const result = await promise;
                if (cancellablePromise.isCancelled) {
                    reject(new Error("Cancelled"));
                } else {
                    resolve(result);
                }
            } catch (error) {
                reject(error);
            }
        });

        cancellablePromise.isCancelled = false;

        return cancellablePromise;
    };

    const handleSetPickedRecipes = async (responseData) => {
        const recipesPromises = [
            createCancellablePromise(fetchOneRecipe(responseData.mon)),
            createCancellablePromise(fetchOneRecipe(responseData.tue)),
            createCancellablePromise(fetchOneRecipe(responseData.wed)),
            createCancellablePromise(fetchOneRecipe(responseData.thu)),
            createCancellablePromise(fetchOneRecipe(responseData.fri)),
            createCancellablePromise(fetchOneRecipe(responseData.sat)),
            createCancellablePromise(fetchOneRecipe(responseData.sun))
        ];

        try {
            const recipes = await Promise.all(recipesPromises);
            console.log("recipes is: " + recipes);
            setPickedRecipes(recipes);
        } catch (error) {
            if (error.message !== "Cancelled") {
                console.error("Failed to fetch recipes:", error);
            }
        }
    };

    const setWeekRecipes = async () => {
        try {
            var week;
            console.log(modalType);
            if (modalType == "pick-this") {
                week = "/setcurrent";
            } else {
                week = "/setnext";
            }

            console.log(week);

            const response = await fetch(
                process.env.REACT_APP_API_URL + "/plan" + week,
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: userToken,
                        mon: pickedRecipes[0].id,
                        tue: pickedRecipes[1].id,
                        wed: pickedRecipes[2].id,
                        thu: pickedRecipes[3].id,
                        fri: pickedRecipes[4].id,
                        sat: pickedRecipes[5].id,
                        sun: pickedRecipes[6].id
                    }),
                    headers: {
                        "Content-type": "application/json;"
                    }
                }
            );
            if (!response.ok) {
                console.log(response);
                throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            console.log(responseData);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        } finally {
            //
        }
    };

    const handleBackClicked = async () => {
        await setWeekRecipes();
        props.handleExitModal();
        setModalVisible(false);
    };

    switch (modalType) {
        case "pick-this":
            return (
                <div className={`modal ${modalVisible ? "visible" : ""}`}>
                    <div className="modal-content">
                        <RecipeGrid
                            modalType={modalType}
                            userToken={props.userToken}
                            pickedRecipes={pickedRecipes}
                            setPickedRecipes={setPickedRecipes}
                            handleSetPickedRecipes={handleSetPickedRecipes}
                        />
                        <button
                            className="close-button"
                            onClick={() => handleBackClicked()}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            );
        case "pick-next":
            return (
                <div className={`modal ${modalVisible ? "visible" : ""}`}>
                    <div className="modal-content">
                        <RecipeGrid
                            modalType={modalType}
                            userToken={props.userToken}
                            pickedRecipes={pickedRecipes}
                            setPickedRecipes={setPickedRecipes}
                            handleSetPickedRecipes={handleSetPickedRecipes}
                        />
                        <button
                            className="close-button"
                            onClick={() => handleBackClicked()}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            );
        case "help":
            return (
                <div className={`modal ${modalVisible ? "visible" : ""}`}>
                    <div className="modal-content">
                        <h1>Help & Support</h1>
                        <p>[CURRENTLY UNDER CONSTRUCTION]</p>
                        <button
                            className="close-button"
                            onClick={() => setModalVisible(false)}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            );
        default:
            return <p>Error</p>;
    }
}
export default Modal;
