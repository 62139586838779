import React, { useState, useEffect } from "react";
import RecipeGridItem from "./RecipeGrid/RecipeGridItem";
import "./RecipeGrid.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PickerSidebar from "./RecipeGrid/PickerSidebar";

/*const testRecipes = [
    {
        id: 1,
        title: "Spaghetti Carbonara",
        photo: "./assets/images/testimage.png"
    },
    {
        id: 2,
        title: "Chicken Alfredo",
        photo: "./assets/images/testimage2.png"
    },
    {
        id: 3,
        title: "Beef Stroganoff",
        photo: "./assets/images/testimage3.png"
    },
    { id: 4, title: "Margarita Pizza", photo: "./assets/images/testimage4.png" }
];*/

const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

function RecipeGrid(props) {
    const { modalType, userToken, pickedRecipes, setPickedRecipes } = props;

    const [recipes, setRecipes] = useState([]);

    // code for fetching data from server, on modal open
    useEffect(() => {
        const fetchAllRecipes = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/recipe/search",
                    {
                        method: "POST",
                        body: JSON.stringify({}),
                        headers: {
                            "Content-type": "application/json;"
                        }
                    }
                );
                if (!response.ok) {
                    console.log(response);
                    throw new Error("Network response was not ok");
                }
                const responseData = await response.json();
                setRecipes(responseData);
                console.log(responseData);
            } catch (error) {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            } finally {
                //
            }
        };

        const fetchWeekRecipes = async () => {
            try {
                var week = "";

                console.log("Modal type is: " + modalType);

                if (modalType == "pick-this") {
                    week = "/current";
                } else {
                    week = "/next";
                }

                console.log("Week is " + week);

                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/plan" + week,
                    {
                        method: "POST",
                        body: JSON.stringify({ token: userToken }),
                        headers: {
                            "Content-type": "application/json;"
                        }
                    }
                );
                if (!response.ok) {
                    console.log(response);
                    throw new Error("Network response was not ok");
                }

                const responseData = await response.json();
                console.log(responseData);
                console.log(responseData);
                console.log("😃😃😃");

                console.log(pickedRecipes);

                props.handleSetPickedRecipes(responseData);
            } catch (error) {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            } finally {
                //
            }
        };

        fetchAllRecipes();
        fetchWeekRecipes();
    }, []);

    const renderTitle = (modalType) => {
        switch (modalType) {
            case "pick-this":
                return "Pick recipes for this week";
            case "pick-next":
                return "Pick recipes for next week";
            default:
                return "[ERROR] Please set ModalType";
        }
    };

    return (
        <div className="recipe-list">
            <DndProvider backend={HTML5Backend}>
                <PickerSidebar
                    className="picker-sidebar"
                    days={days}
                    pickedRecipes={pickedRecipes}
                    setPickedRecipes={setPickedRecipes}
                />
                <div className="vertical-divider"></div>
                <div>
                    <div className="title">
                        <h2>Pick Recipes</h2>
                        <p>{renderTitle(modalType)}</p>
                    </div>
                    <div className="recipe-grid">
                        {recipes.map((recipe, index) => (
                            <RecipeGridItem
                                key={recipe.id}
                                recipe={recipe}
                                index={index}
                            />
                        ))}
                    </div>
                </div>
            </DndProvider>
        </div>
    );
}

export default RecipeGrid;
