import React, { useState } from "react";
import "./Login.css";

function Login(props) {
    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Test login info
    let database = props.database;
    var isLoggedIn = props.isLoggedIn;
    const setIsLoggedIn = props.setIsLoggedIn;

    // Error messages
    const errors = {
        user_name: "invalid username",
        pass: "invalid password"
    };

    // code for fetching data from server

    const fetchData = async (emailStr, passwordStr) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/auth/login", {
                method: "POST",
                body: JSON.stringify({
                    email: emailStr,
                    password: passwordStr
                }),
                headers: {
                    "Content-type": "application/json;"
                }
            });
            if (!response.ok) {
                console.log(response);
                throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            console.log(responseData.token);
            props.setUserToken(responseData.token);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        } finally {
            //
        }
    };

    const handleSubmit = (event) => {
        //Prevent reloading
        event.preventDefault();

        var { user_name, password } = document.forms[0];

        console.log(user_name.value);
        console.log(password.value);

        fetchData(user_name.value, password.value);

        /*
        // Get user login info
        const userData = database.find(
            (user) => user.username === user_name.value
        );

        
        
        // Compare user info
        if (userData) {
            if (userData.password !== password.value) {
                // Invalid password
                setErrorMessages({ name: "pass", message: errors.pass });
            } else {
                setIsLoggedIn(true);
            }
        } else {
            // Username not found
            setErrorMessages({ name: "user_name", message: errors.user_name });
        }
        */
    };

    // Error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    // Login form
    const renderLoginForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="input-container padded">
                    <label className="input-label">Email: </label>
                    <input
                        className="input-text-field"
                        type="text"
                        name="user_name"
                        required
                    />
                    {renderErrorMessage("user_name")}
                </div>
                <div className="input-container padded">
                    <label className="input-label">Password: </label>
                    <input
                        className="input-text-field"
                        type="password"
                        name="password"
                        required
                    />
                    {renderErrorMessage("pass")}
                </div>
                <div className="button-container padded">
                    <input className="button" type="submit" value="Sign in" />
                </div>
            </form>
        </div>
    );

    return (
        <div className="login">
            <div className="login-form">
                <h1 className="">Sign In</h1>
                <p>
                    {" "}
                    Welcome to Smart Cooking, please log in or create an account
                    to continue{" "}
                </p>
                {renderLoginForm}
            </div>
            <div>
                <p>Haven't got an account?</p>
                <button
                    className="button help"
                    onClick={props.handleShowRegisterPage}
                >
                    Register
                </button>
            </div>
        </div>
    );
}

export default Login;
