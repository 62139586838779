import React from "react";
import "./MainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faRuler } from "@fortawesome/free-solid-svg-icons";

function MainContent(props) {
    const { selectedItem } = props;

    const renderContent = () => {
        return (
            <>
                <div className="title-row">
                    <div className="title-container">
                        <div className="title-container-title-text">
                            <h1>{selectedItem.name}</h1>
                            <div>
                                <button
                                    className="cook-button"
                                    onClick={props.handleCookButtonPressed}
                                >
                                    <FontAwesomeIcon
                                        className="fa-shadow"
                                        icon={faCirclePlay}
                                        size="xl"
                                    />
                                    <p className="button-text">COOK</p>
                                </button>
                                <button
                                    className="adjust-button"
                                    onClick={null}
                                >
                                    <FontAwesomeIcon
                                        className="fa-shadow"
                                        icon={faRuler}
                                        size="xl"
                                    />
                                    <p className="button-text">ADJUST</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="recipe-image-container">
                        <img
                            className="recipe-image"
                            src={process.env.REACT_APP_API_URL + selectedItem.img_uri}
                            alt={selectedItem.image_uri}
                        />
                    </div>
                </div>
                <div className="horizontal-divider"></div>
                <p>{selectedItem.description}</p>
                <h3>Ingredients</h3>
                <div className="ingredients-list">
                    {selectedItem.ingredients.map((ingredient) => (
                        <div className="ingredients-list-item">
                            <p className="ingredient-header">
                                {ingredient.name}
                            </p>
                            <img
                                className="ingredient-image"
                                src={
                                    process.env.REACT_APP_API_URL + ingredient.img_uri
                                }
                                alt={ingredient.name}
                            />
                            <p>
                                {ingredient.quantity}
                                {renderDisplayType(
                                    ingredient.quantity_display_type
                                )}
                            </p>
                        </div>
                    ))}
                </div>
                <h3>Cooking steps</h3>
                <div className="steps-list">
                    {selectedItem.steps.map((step, index) => (
                        <div className="steps-list-item">
                            <p className="step-header">Step {index + 1}</p>
                            <p>{step.step}</p>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderDisplayType = (display_type) => {
        switch (display_type) {
            case 0:
                return "Each";
            case 1:
                return "Mass";
            case 2:
                return "Volume";
        }
    };

    return (
        <div className="main-content">
            {selectedItem ? (
                renderContent()
            ) : (
                <div>
                    <h1>Welcome to Smart Cooking</h1>
                    <p>Please select an item from the sidebar.</p>
                    <h1>☜</h1>
                </div>
            )}
        </div>
    );
}

export default MainContent;
