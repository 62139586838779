import React, { useState } from "react";
import Header from "../components/Header";
import Body from "../components/Body";

import "./App.css";

// Hacky workaround for dark background
//document.body.style = 'background:   #333333;';

function App() {
    // [DEBUG] SET THIS TO TRUE TO BYPASS LOGIN

    var cookie = document.cookie;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userToken, setUserToken] = useState();

    const handleSetUserToken = (token) => {
        setUserToken(token);
        setIsLoggedIn(true);
    };

    const [activeTab, setActiveTab] = useState(0);

    // Currently active recipe- for use with cook mode
    const [activeRecipe, setActiveRecipe] = useState(null);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleCookButtonPressed = () => {
        setActiveTab(1);
    };

    const handleRecipeButtonPressed = () => {
        setActiveTab(0);
    };

    const handleSelectActiveRecipe = (item) => {
        setActiveRecipe(item);
        console.log(activeRecipe);
    };

    return (
        <div className="container">
            <Header
                isLoggedIn={isLoggedIn}
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                userToken={userToken}
            />
            <Body
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                activeTab={activeTab}
                activeRecipe={activeRecipe}
                handleSelectActiveRecipe={handleSelectActiveRecipe}
                handleCookButtonPressed={handleCookButtonPressed}
                handleRecipeButtonPressed={handleRecipeButtonPressed}
                setUserToken={handleSetUserToken}
                userToken={userToken}
            />
        </div>
    );
}

export default App;
