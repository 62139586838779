import React from "react";
import "./PickerSidebar.css";
import DayItem from "./PickerSidebar/DayItem";
import { useDrop } from "react-dnd";

function PickerSidebar(props) {
    const { days, pickedRecipes, setPickedRecipes } = props;

    return (
        <div className={"picker-sidebar"}>
            {days.map((day, index) => (
                <DayItem
                    key={index}
                    day={day}
                    index={index}
                    pickedRecipes={pickedRecipes}
                    setPickedRecipes={setPickedRecipes}
                />
            ))}
        </div>
    );
}

export default PickerSidebar;
