import React, { useState, useEffect } from "react";
import "./Header.css";
import "../assets/fonts/dolphins.ttf";
import TabBar from "../components/Header/TabBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faSliders } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Header/Modal";

//header
function Header(props) {
    var isLoggedIn = props.isLoggedIn;

    const [userModalVisible, setUserModalVisible] = useState(false);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);

    const activeTab = props.activeTab;
    const [username, setUsername] = useState("Account");
    const [email, setEmail] = useState(null);

    const handleTabClick = (index) => {
        props.handleTabClick(index);
    };

    const title = (
        <>
            <h1 className="title-font">Smart Cooking</h1>
        </>
    );

    const fetchUsername = async (userToken) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/auth/identity",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: userToken
                    }),
                    headers: {
                        "Content-type": "application/json;"
                    }
                }
            );
            if (!response.ok) {
                console.log(response);
                throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            console.log(responseData);
            setUsername(responseData.name);
            setEmail(responseData.email);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };

    if (isLoggedIn && username == "Account") {
        fetchUsername(props.userToken);
    }

    return (
        <div className="header">
            {title}
            {isLoggedIn ? (
                <TabBar handleTabClick={handleTabClick} activeTab={activeTab} />
            ) : null}
            {isLoggedIn ? (
                <div className="trailing-buttons">
                    <button
                        className="user-button"
                        onClick={() => setUserModalVisible(true)}
                    >
                        <FontAwesomeIcon
                            className="user-button-fa-icon"
                            icon={faCircleUser}
                            size="xl"
                        />
                        <span>{username}</span>
                    </button>
                    <button
                        className="settings-button"
                        onClick={() => setSettingsModalVisible(true)}
                    >
                        <FontAwesomeIcon
                            className="settings-button-fa-icon"
                            icon={faSliders}
                            size="xl"
                        />
                    </button>
                </div>
            ) : null}
            {userModalVisible && (
                <Modal
                    modalVisible={userModalVisible}
                    setModalVisible={setUserModalVisible}
                    modalType="user"
                    username={username}
                    email={email}
                />
            )}
            {settingsModalVisible && (
                <Modal
                    modalVisible={settingsModalVisible}
                    setModalVisible={setSettingsModalVisible}
                    modalType="settings"
                    username={username}
                    email={email}
                />
            )}
        </div>
    );
}

export default Header;
