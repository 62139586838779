import React, { useState } from "react";
import "./Login.css";

function Login(props) {
    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Test login info
    let database = props.database;
    var isLoggedIn = props.isLoggedIn;
    const setIsLoggedIn = props.setIsLoggedIn;

    // Error messages
    const errors = {
        user_name: "invalid username",
        pass: "invalid password"
    };

    // code for fetching data from server

    const fetchData = async (emailStr, passwordStr, displayNameStr) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/auth/register",
                {
                    method: "POST",
                    body: JSON.stringify({
                        email: emailStr,
                        name: displayNameStr,
                        password: passwordStr
                    }),
                    headers: {
                        "Content-type": "application/json;"
                    }
                }
            );
            if (!response.ok) {
                console.log(response);
                throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            console.log(responseData);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        } finally {
            //
        }
    };

    const handleSubmit = (event) => {
        //Prevent reloading
        event.preventDefault();

        var { user_name, password, display_name } = document.forms[0];

        console.log(user_name.value);
        console.log(password.value);
        console.log(display_name.value);

        fetchData(user_name.value, password.value, display_name.value);
    };

    // Error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    // Register form
    const renderRegisterForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="input-container padded">
                    <label className="input-label">Email: </label>
                    <input
                        className="input-text-field"
                        type="text"
                        name="user_name"
                        required
                    />
                    {renderErrorMessage("user_name")}
                </div>
                <div className="input-container padded">
                    <label className="input-label">Password: </label>
                    <input
                        className="input-text-field"
                        type="password"
                        name="password"
                        required
                    />
                    {renderErrorMessage("pass")}
                </div>
                <div className="input-container padded">
                    <label className="input-label">First Name: </label>
                    <input
                        className="input-text-field"
                        type="text"
                        name="display_name"
                        required
                    />
                    {renderErrorMessage("pass")}
                </div>
                <div className="button-container padded">
                    <input className="button" type="submit" value="Register" />
                </div>
            </form>
        </div>
    );

    return (
        <div className="login">
            <div className="login-form">
                <h1 className="">Register</h1>
                <p>
                    {" "}
                    Welcome to Smart Cooking, please log in or create an account
                    to continue{" "}
                </p>
                {renderRegisterForm}
            </div>
            <div>
                <p>Already got an account?</p>
                <button
                    className="button help"
                    onClick={props.handleHideRegisterPage}
                >
                    Log in
                </button>
            </div>
        </div>
    );
}

export default Login;
