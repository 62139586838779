import React from "react";
import "./SidebarItem.css";

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function SidebarItem(props) {
    const { item, index, onItemClick, isSelected } = props;

    var date = new Date();
    date.setDate(date.getDate() - (date.getDay() || 7) + 1);
    date = addDays(date, index);
    const dayOfWeek = date
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
    const dayOfMonth = date.toLocaleDateString("en-US", {
        day: "numeric",
        suffix: "numeric"
    });

    return (
        <div
            className={`sidebar-item ${isSelected ? "selected" : ""}`}
            onClick={() => onItemClick(item)}
        >
            <div className="date-box">
                <div className="day-of-week">{dayOfWeek}</div>
                <div className="day-of-month">{dayOfMonth}</div>
            </div>

            <h3 className="item-title">{item.name}</h3>
            <img
                src={process.env.REACT_APP_API_URL + item.img_uri}
                alt={item.image_uri}
            />
        </div>
    );
}

export default SidebarItem;
