import React, { useState } from "react";
import SidebarItem from "./Sidebar/SidebarItem";
import "./Sidebar.css";
import Modal from "./Sidebar/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleQuestion,
    faUtensils
} from "@fortawesome/free-solid-svg-icons";

function Sidebar(props) {
    const { items, onItemClick, selectedItem } = props;

    const [currentPickerModalVisible, setCurrentPickerModalVisible] =
        useState(false);
    const [nextPickerModalVisible, setNextPickerModalVisible] = useState(false);
    const [helpModalVisible, setHelpModalVisible] = useState(false);

    const [nextWeekEmpty, setNextWeekEmpty] = useState(true);

    return (
        <div className="sidebar">
            <div className="week-header this-week">
                <h3 className="week-header-title">THIS WEEK</h3>
                <button
                    className="button button-edit"
                    display="hidden"
                    onClick={() => setCurrentPickerModalVisible(true)}
                >
                    EDIT
                </button>
            </div>
            {items
                .filter((item) => item !== null && item !== undefined)
                .map((item, index) => (
                    <SidebarItem
                        key={index}
                        index={index}
                        item={item}
                        onItemClick={onItemClick}
                        isSelected={
                            selectedItem !== null && selectedItem.id === item.id
                        }
                    />
                ))}
            <div className="week-header this-week">
                <h3 className="week-header-title">NEXT WEEK</h3>

                <button
                    className={`button button-edit ${
                        nextWeekEmpty ? "hidden" : ""
                    }`}
                    display="hidden"
                    onClick={() => setNextPickerModalVisible(true)}
                >
                    EDIT
                </button>
            </div>
            <button
                className={`button pick ${nextWeekEmpty ? "" : "hidden"}`}
                onClick={() => setNextPickerModalVisible(true)}
            >
                <FontAwesomeIcon
                    className="fa-shadow sidebar-icon"
                    icon={faUtensils}
                    size="lg"
                />
                <span>PICK RECIPES</span>
            </button>

            <div className="divider-line"></div>
            <button
                className="button help"
                onClick={() => setHelpModalVisible(true)}
            >
                <FontAwesomeIcon
                    className="fa-shadow sidebar-icon"
                    icon={faCircleQuestion}
                    size="lg"
                />
                <span>HELP & SUPPORT</span>
            </button>
            {currentPickerModalVisible && (
                <Modal
                    modalVisible={currentPickerModalVisible}
                    setModalVisible={setCurrentPickerModalVisible}
                    modalType="pick-this"
                    userToken={props.userToken}
                    handleExitModal={props.handleExitModal}
                />
            )}
            {nextPickerModalVisible && (
                <Modal
                    modalVisible={nextPickerModalVisible}
                    setModalVisible={setNextPickerModalVisible}
                    modalType="pick-next"
                    userToken={props.userToken}
                    handleExitModal={props.handleExitModal}
                />
            )}
            {helpModalVisible && (
                <Modal
                    modalVisible={helpModalVisible}
                    setModalVisible={setHelpModalVisible}
                    modalType="help"
                    userToken={props.userToken}
                />
            )}
        </div>
    );
}

export default Sidebar;
