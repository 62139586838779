import React, { useState } from "react";
import Login from "../components/Body/Login";
import Register from "../components/Body/Register";
import Home from "../components/Body/Home";

import Recipes from "../components/Body/Recipes";
import Cook from "../components/Body/Cook";
import Fridge from "../components/Body/Fridge";
import ShoppingList from "../components/Body/ShoppingList";

import "./Body.css";

const testdb = [
    {
        username: "user1",
        password: "pass1"
    },
    {
        username: "user2",
        password: "pass2"
    }
];

function renderSwitch(activeTab, props) {
    switch (activeTab) {
        case 0:
            return (
                <Recipes
                    handleSelectActiveRecipe={props.handleSelectActiveRecipe}
                    handleCookButtonPressed={props.handleCookButtonPressed}
                    activeRecipe={props.activeRecipe}
                    userToken={props.userToken}
                />
            );
        case 1:
            return (
                <Cook
                    activeRecipe={props.activeRecipe}
                    handleRecipeButtonPressed={props.handleRecipeButtonPressed}
                />
            );
        case 2:
            return <Fridge />;
        case 3:
            return <ShoppingList />;
        default:
            return <Home />;
    }
}

function Body(props) {
    console.log(props);

    const [registerPageShown, setRegisterPageShown] = useState(true);

    const handleShowRegisterPage = () => {
        setRegisterPageShown(true);
    };

    const handleHideRegisterPage = () => {
        setRegisterPageShown(false);
    };

    var isLoggedIn = props.isLoggedIn;
    var setIsLoggedIn = props.setIsLoggedIn;

    var activeTab = props.activeTab;

    if (isLoggedIn === true) {
        return <div className="body">{renderSwitch(activeTab, props)}</div>;
    } else {
        if (registerPageShown === false) {
            return (
                <div className="body">
                    <Login
                        database={testdb}
                        setIsLoggedIn={setIsLoggedIn}
                        setUserToken={props.setUserToken}
                        handleShowRegisterPage={handleShowRegisterPage}
                    />
                </div>
            );
        } else {
            return (
                <div className="body">
                    <Register
                        database={testdb}
                        setIsLoggedIn={setIsLoggedIn}
                        setUserToken={props.setUserToken}
                        handleHideRegisterPage={handleHideRegisterPage}
                    />
                </div>
            );
        }
    }
}

export default Body;
