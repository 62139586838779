import React, { useState } from "react";
import { useDrop } from "react-dnd";
import "./DayItem.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DayItemRecipeCard from "./DayItem/DayItemRecipeCard";

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update state to force render
}

function DayItem(props) {
    const forceUpdate = useForceUpdate();

    const [state, setState] = useState();

    const { day, index, pickedRecipes, setPickedRecipes } = props;

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: "RECIPEGRIDITEM",
            drop: (item) => {
                console.log(item.recipe);
                if (pickedRecipes[index] !== item.recipe) {
                    pickedRecipes[index] = item.recipe;
                }
                console.log(pickedRecipes);
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }),
        [pickedRecipes]
    );

    const date = new Date();
    const dayOfWeek = date
        .toLocaleDateString("en-US", { weekday: "short" })
        .toUpperCase();
    const dayOfMonth = date.toLocaleDateString("en-US", {
        day: "numeric",
        suffix: "numeric"
    });

    const removeRecipe = (index) => {
        const temp = pickedRecipes;
        temp[index] = null;
        setPickedRecipes(temp);
        forceUpdate();
    };

    const RecipeCard = () => {
        if (pickedRecipes[index] != null) {
            return (
                <DayItemRecipeCard
                    recipe={pickedRecipes[index]}
                    index={index}
                    deletionFunc={() => removeRecipe(index)}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div
            className={`day-container ${isOver ? "selected" : ""}`}
            key={day}
            ref={drop}
        >
            <h3 className="day-label">{day}</h3>
            {RecipeCard()}
        </div>
    );
}

export default DayItem;
