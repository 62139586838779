import React, { useState, useEffect } from "react";
import "./Recipes.css";
import Sidebar from "./Recipes/Sidebar";
import SidebarItem from "./Recipes/Sidebar/SidebarItem";
import MainContent from "./Recipes/MainContent";

function Recipes(props) {
    const [data, setData] = useState([]);

    const handleExitModal = () => {
        loadWeek();
    };

    // code for fetching data from server
    useEffect(() => {
        loadWeek();
    }, []);

    const loadWeek = () => {
        let recipesPromises = [];
        const fetchWeekRecipes = async () => {
            try {
                var week = "/current";

                console.log("Week is " + week);

                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/plan" + week,
                    {
                        method: "POST",
                        body: JSON.stringify({ token: props.userToken }),
                        headers: {
                            "Content-type": "application/json;"
                        }
                    }
                );
                if (!response.ok) {
                    console.log(response);
                    throw new Error("Network response was not ok");
                }

                const responseData = await response.json();
                console.log(responseData);
                console.log("😃😃😃");

                return responseData;
            } catch (error) {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            } finally {
                //
            }
        };

        const fetchOneRecipe = async (index) => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/recipe/" + index,
                    {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json;"
                        }
                    }
                );
                if (!response.ok) {
                    console.log(response);
                    throw new Error("Network response was not ok");
                    return null;
                }
                const responseData = await response.json();
                console.log(responseData);
                return responseData;
            } catch (error) {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            } finally {
                //
            }
        };

        const createCancellablePromise = (promise) => {
            const cancellablePromise = new Promise(async (resolve, reject) => {
                try {
                    const result = await promise;
                    if (cancellablePromise.isCancelled) {
                        reject(new Error("Cancelled"));
                    } else {
                        resolve(result);
                    }
                } catch (error) {
                    reject(error);
                }
            });

            cancellablePromise.isCancelled = false;

            return cancellablePromise;
        };

        const getWeekItems = async (indices) => {
            recipesPromises = [
                createCancellablePromise(fetchOneRecipe(indices.mon)),
                createCancellablePromise(fetchOneRecipe(indices.tue)),
                createCancellablePromise(fetchOneRecipe(indices.wed)),
                createCancellablePromise(fetchOneRecipe(indices.thu)),
                createCancellablePromise(fetchOneRecipe(indices.fri)),
                createCancellablePromise(fetchOneRecipe(indices.sat)),
                createCancellablePromise(fetchOneRecipe(indices.sun))
            ];

            try {
                const recipes = await Promise.all(recipesPromises);
                console.log(recipes);
                return recipes;
            } catch (error) {
                if (error.message !== "Cancelled") {
                    console.error("Failed to fetch recipes:", error);
                }
            }
        };

        const fetchData = async () => {
            const arr = await fetchWeekRecipes();
            if (arr.has_data == false) {
                console.log("No data");
                setData([null, null, null, null, null, null, null]);
                return;
            }
            console.log("🥶array be like");
            console.log(arr);
            const items = await getWeekItems(arr);
            console.log("🥶items be like");
            console.log(items);
            setData(items);
            //console.log("🥶setdata be like");
            //console.log(this.data);
        };

        fetchData();

        return () => {
            for (const promise of recipesPromises) {
                promise.isCancelled = true;
            }
        };
    };

    console.log(props);

    const [selectedItem, setSelectedItem] = useState(props.activeRecipe);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        props.handleSelectActiveRecipe(item);
    };

    return (
        <div className="recipes">
            <Sidebar
                items={data}
                onItemClick={handleItemClick}
                selectedItem={selectedItem}
                userToken={props.userToken}
                handleExitModal={handleExitModal}
            />
            <MainContent
                selectedItem={selectedItem}
                handleCookButtonPressed={props.handleCookButtonPressed}
            />
        </div>
    );
}

export default Recipes;
