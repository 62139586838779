import React, { useState } from "react";
import "./Modal.css";

function Modal(props) {
    const { modalVisible, setModalVisible, modalType, username, email } = props;

    switch (modalType) {
        case "user":
            return (
                <div className={`modal ${modalVisible ? "visible" : ""}`}>
                    <div className="modal-content">
                        <h1>User account information</h1>
                        <h3>Current username:</h3>
                        <p>{username}</p>
                        <h3>Current email:</h3>
                        <p>{email}</p>
                        <button
                            className="close-button"
                            onClick={() => setModalVisible(false)}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            );
        case "settings":
            return (
                <div className={`modal ${modalVisible ? "visible" : ""}`}>
                    <div className="modal-content">
                        <h1>Settings</h1>
                        <p>[UNDER CONSTRUCTION]</p>
                        <button
                            className="close-button"
                            onClick={() => setModalVisible(false)}
                        >
                            DONE
                        </button>
                    </div>
                </div>
            );
        default:
            return <p>Error</p>;
    }
}
export default Modal;
