import React, { useState } from "react";
import "./DayItemRecipeCard.css";
import { useDrag } from "react-dnd";

function DayItemRecipeCard({ recipe, index, deletionFunc }) {
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: "RECIPEGRIDITEM",
            item: { recipe },
            drag: (item) => {
                console.log(item.recipe);
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                didDrop: monitor.didDrop()
            }),
            end: (item, monitor) => {
                deletionFunc();
            }
        }),
        [recipe]
    );

    return (
        <div
            ref={dragPreview}
            style={{ opacity: isDragging ? 0.4 : 1, cursor: "move" }}
            className=""
        >
            <div className="day-item-recipe-container" role="Handle" ref={drag}>
                <img
                    className="day-item-recipe-image"
                    draggable="false"
                    src={process.env.REACT_APP_API_URL + recipe.img_uri}
                    alt={recipe.img_uri}
                />
                <p className="day-item-recipe-name">{recipe.name}</p>
            </div>
        </div>
    );
}

export default DayItemRecipeCard;
