import React, { useState } from "react";
import "./TabBar.css";

function TabBar(props) {
    const activeTab = props.activeTab;

    const handleTabClick = (index) => {
        props.handleTabClick(index);
    };

    return (
        <div className="tab-bar">
            <div className="tab-list">
                <div
                    className={`tab-item tab-recipe ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleTabClick(0)}
                >
                    <span>Recipes</span>
                </div>
                <div
                    className={`tab-item tab-cook ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabClick(1)}
                >
                    <span>Cook</span>
                </div>
                <div
                    className={`tab-item tab-fridge ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleTabClick(2)}
                >
                    <span>Fridge</span>
                </div>
                <div
                    className={`tab-item tab-shopping ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => handleTabClick(3)}
                >
                    <span>Shopping List</span>
                </div>
            </div>
        </div>
    );
}

export default TabBar;
