import React from "react";
import "./Home.css"



function Home(props) {
  return (
  <div className="home">
    <h1>{props.title}</h1>
    <p>Welcome to Smart Cooking</p>
    <p>You are now logged in</p>
    
  </div>
  );
}

export default Home;