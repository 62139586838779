import React from "react";
import "./RecipeGridItem.css";
import { useDrag } from "react-dnd";

function RecipeGridItem({ recipe, index }) {
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: "RECIPEGRIDITEM",
            item: { recipe },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                didDrop: monitor.didDrop()
            }),
            end: (item, monitor) => ({
                if(didDrop) {}
            })
        }),
        []
    );

    return (
        <div
            ref={dragPreview}
            style={{ opacity: isDragging ? 0.4 : 1, cursor: "move" }}
            className="recipe-tile"
        >
            <div role="Handle" ref={drag}>
                <img
                    draggable="false"
                    src={process.env.REACT_APP_API_URL + "/" + recipe.img_uri}
                    alt={recipe.img_uri}
                />
                <p className="recipe-tile-title">{recipe.name}</p>
            </div>
        </div>
    );
}

export default RecipeGridItem;
